import React from "react"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

export default function PolicySafeguarding() {
  return ( 
    <Layout>
        <section className="container">
            <h1>Safeguarding Policy <a href="downloads/LEEDS CITY JUNIORS FC CHILD PROTECTION POLICY[69].pdf" target="_blank" title="download policy"><FontAwesomeIcon className="lcj-color" icon={faFileDownload}></FontAwesomeIcon></a></h1>
            <InnerPageTwitterLayout>
<div className="small">

<h5>LEEDS CITY JUNIORS FC CHILD PROTECTION POLICY </h5>
<p>
1. Leeds City Juniors Football Club (LCJFC) acknowledges its responsibility to safeguard the welfare of every child & young person who has been entrusted to its care & is committed to working to provide a safe environment for all members. A child or young person is anyone under the age of 18 engaged in any club football activity. We subscribe to the Football Association’s (FA) Safeguarding Children – Policy and Procedures and endorse and adopt the policy statement contained in that document. 
</p>

<p>
2. The key principles of the FA child protection policy are:- 
<ul>
<li>The child’s welfare is, & must always be, the paramount consideration. </li>
<li>All children & young people have a right to be protected from abuse regardless of their age, gender, disability, race, sexual orientation, faith or belief </li>
<li>All suspicions & allegations of abuse will be taken seriously & responded to swiftly & appropriately. </li>
<li>Working in partnership with other organisations, children & young people & their parents/carers is essential. </li>
</ul>
We acknowledge that every child or young person who plays or participates in football should be able to take part in an enjoyable & safe environment & be protected from poor practice & abuse. 
LCJFC recognises this is the responsibility of every adult involved in our club. 
</p>

<p>
3. LCJFC has a role to play in safeguarding the welfare of all children & young people by protecting them from physical, sexual or emotional harm & from neglect or bullying. It is noted & accepted the FA’s Safeguarding Children Regulations (see FA Handbook) applies to everyone in football whether in a paid or voluntary capacity. This means whether you are a volunteer, match official, helper on club tours, football coach, club official or medical staff. 
</p>

<p>
4. We endorse & adopt the FA’s Responsible Recruitment guidelines for recruiting volunteers & we will:- 
<ul>
<li>Develop a role profile </li>    
<li>Request identification documents. </li>    
<li>As a minimum meet & chat with the applicant(s) &, where possible, conduct interviews before appointing them. </li>    
<li>Request a CV relating to previous voluntary work & follow up with two references before appointing someone. </li>    
<li>Require an FA accepted Enhanced Criminal Record Check (CRC) with Barring List Check in line with current FA policy and regulations. </li>    
</ul>
All current LCJFC members who are regularly caring for, supervising, training or being in sole charge of children and young people will be required to complete a CRB Enhanced Disclosure via the FA CRB Unit. If there are concerns regarding the appropriateness of an individual who is already involved or who has approached us to become part of LCJFC, guidance will be sought from the FA. It is noted & accepted that The FA will consider the relevance & significance of the information obtained via the CRC process & that all decisions will be made in accordance with the legislation and in the best interests of children & young people. 
It is accepted that The FA aims to prevent people with a history of relevant & significant offending from having contact with children or young people & the opportunity to influence policies or practice with children or young people. This is to prevent direct sexual or physical harm to children & to minimise the risk of ‘grooming’ within football. 
</p>

<p>
5. LCJFC supports the FA’s whistle blowing policy. Any adult or young person with concerns about a colleague can ‘whistle blow’ by contacting the FA Safeguaring Team on 0844 980 8200 4787, by writing to The FA Case Manager at The Football Association, Wembley Stadium, PO Box 1966, London SW10 9EQ or alternatively by going direct to the Police, Children’s Social Care or the NSPCC. Our Club encourages everyone to know about this information and utilise it if necessary. 
</p>

<p>
6. LCJFC has appointed a Club Welfare Officer (CWO) in line with the FA’s role profile & required completion of the Safeguarding Children and Welfare Officers Workshop. The post holder will be involved with Welfare Officer training provided by the FA and/or CFA. The CWO is the first point of contact for all Club Committee members regarding concerns for the welfare of any child or young person. The CWO will liaise directly with the CFA Welfare Officer & will be familiar with the procedures for referring any concerns. They will also play a proactive role in increasing an awareness of Respect, poor practice & abuse amongst club members. 
</p>

<p>
7. We acknowledge & endorse the FA’s identification of bullying as a category of abuse. Bullying of any kind is not acceptable at our club. If bullying does occur, all players, parents/carers should be able to tell & know incidents will be dealt with promptly. Incidents need to be reported to the CWO, or alternatively, in cases of serious bullying, we may contact the CFA Welfare Officer. 
</p>

<p>
8. Respect codes of conduct for Players, Parents/Spectators, Officials & and Coaches have been implemented by LCJFC. In order to validate these Respect codes of conduct the club has clear actions it will take regarding repeated or serious misconduct at Club level and acknowledges the possibility of potential sanctions which may be implemented by the CFA in more serious circumstances. All registering members will be required to adopt these codes. 
</p>

<p>
9. Reporting your concerns about the welfare of a child or young person – Safeguarding is everyone’s responsibility. If you are worried about a child it is important that you report your concerns – no action is not an option. • If you are worried about a child then you need to report your concerns to the CWO 
<ul>
<li>If the issue is one of poor practice they will either: i. Deal with the matter themselves or 
ii. Seek advice from the CFA Welfare Officer 
</li>
<li>If the concern is more serious – possible child abuse – they will, where possible, contact the CFA Welfare Officer first, then immediately contact the Police or Children’s Services </li>
<li>If the child needs immediate medical treatment take them to a hospital or call an ambulance and tell the, this is a child protection concern. Let your CFA Welfare Officer know what action you have taken </li>
<li>If at any time you are not able to contact your Club Welfare Officer or the matter is clearly serious then either: i. Contact your CFA Welfare Officer directly ii. Contact the Police or Children’s Social Care 
iii. Call the NSPCC 24 hour Helpline for advice on 0808 800 5000 or tect 88858 or email help@nspcc.org.uk 
</li>
</ul>

Please note: The FA’s Safeguarding Children Policy and Procedures is available via <a href="http://www.thefa.com/football-rules-governance/safeguarding/dealing-with-concerns">http://www.thefa.com/football-rules-governance/safeguarding/dealing-with-concerns</a> Click on the ‘downloads’ under Policy and Procedures. It outlines in detail what to do if you are concerned about the welfare of a child and includes flow diagrams to describe this process. This is also covered within the Safeguarding Children workshop, where participants are given the opportunity to discuss how this feels and how best they can prepare themselves to deal with such a situation. 
</p>

<p>
10. Further advice on child protection matters can be obtained from: <br/><br/>

LCJFC Child Welfare Officer: <br/>
Lee Orange <br/>
Tel: 07743420677<br/> 
Email: lee.orange@leedscityjuniors.org.uk <br/><br/>

County Football Association’s Welfare Officer: <br/>
Paul Ratcliffe <br/>
Tel: 0113 282 1222 <br/>
Email: paul.ratcliffe@wrcfa.com <br/><br/>

General: <br/>
NSPCC 24 hour helpline 0808 800 5000. <br/>
http://www.thefa.com/football-rules-governance/safeguarding <br/>
The FA Safeguarding Children general enquiry line 0845210 8080 
</p>

</div> 
            </InnerPageTwitterLayout>
        </section>
    </Layout>
  );
}
